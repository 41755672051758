<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
              align-self="center"
              class="display-3"
            >
              MITMACHEN
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pt-5 px-5" color="rgba(151, 191, 13, 0.2)">
          <v-card-text
            class="black--text"
          >
            <div v-html="settings[0]?.pages?.find(p => p.type === 'participate')?.text"></div>
            <v-btn small color="success" @click="setShowLogin('signup')">Jetzt schnell anmelden und dabei sein!</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'about',
  components: {
  },
  computed: {
    ...mapGetters('users', {
      'users': 'list'
    }),
    ...mapGetters('settings', {
      'settings': 'list'
    })
  },
  methods: {
    ...mapMutations({
      'setShowLogin': 'SET_SHOW_LOGIN'
    })
  }
}
</script>
