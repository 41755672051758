import Vue from 'vue'
import Store from '@/store'
import Multiguard from 'vue-router-multiguard'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Data from '../views/Data.vue'
import Imprint from '../views/Imprint.vue'
import Users from '../views/Users.vue'
import User from '../views/User.vue'
import UserEditor from '../views/UserEditor.vue'
import Books from '../views/Books.vue'
import Book from '../views/Book.vue'
import BookEditor from '../views/BookEditor.vue'
import VoteEditor from '../views/VoteEditor.vue'
import SettingsEditor from '../views/SettingsEditor.vue'
import Votes from '../views/Votes.vue'
import Verify from '../views/Verify.vue'
import WinnersList from '../views/WinnersList.vue'
import Winners from '../views/Winners.vue'
import WinnersEditor from '../views/WinnersEditor.vue'

Vue.use(VueRouter)

let user
let ac

const waitFor = async (to, from, next) => {
  if (Store.state.system.init) {
    // Login
    if (localStorage['feathers-jwt']) {
      try {
        await Store.dispatch('auth/authenticate')
      } catch (e) {
        localStorage.removeItem('feathers-jwt')
        history.pushState({}, null, '/')
        location.reload()
      }
      await Store.dispatch('accesscontrol/get', { paginate: false })
      await Store.dispatch('users/find', { paginate: false, query: { $populate: ['votes'] } })
      //
      ac = Store.getters['ac']
      user = Store.getters['auth/user']
    }
    await Store.dispatch('settings/find', { paginate: false })
    await Store.dispatch('books/find', { paginate: false, query: { $populate: ['rental', 'votes'] } })
    await Store.dispatch('winners/find', { paginate: false })
    Store.commit('SET_INIT', false)
    Store.commit('SET_SHOW', true)
  }
  Store.commit('SET_SHOW_NAV', false)
  if (next) {
    next()
  }
}

const routes = [
  {
    path: '/buecher',
    name: 'books',
    component: Books,
    beforeEnter: Multiguard([
      waitFor
    ])
  },
  {
    path: '/:showLogin?',
    name: 'home',
    component: Home,
    beforeEnter: Multiguard([
      waitFor
    ])
  },
  {
    path: '/info/impressum',
    name: 'imprint',
    component: Imprint,
    beforeEnter: Multiguard([
      waitFor
    ])
  },
  {
    path: '/info/datenschutz',
    name: 'dataPolicy',
    component: Data,
    beforeEnter: Multiguard([
      waitFor
    ])
  },
  {
    path: '/siegertitel/:year',
    name: 'winners',
    component: Winners,
    beforeEnter: Multiguard([
      waitFor
    ])
  },
  {
    path: '/intern/siegertitel',
    name: 'winnersLust',
    component: WinnersList,
    beforeEnter: Multiguard([
      waitFor
    ])
  },
  {
    path: '/intern/siegertitel/editor/:id?',
    name: 'winnersEditor',
    component: WinnersEditor,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          user &&
          ac &&
          ac.can(user.role).createAny('winner').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/intern/einstellungen',
    name: 'settingsEditor',
    component: SettingsEditor,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          user &&
          ac &&
          ac.can(user.role).updateAny('setting').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/intern/nutzer/',
    name: 'users',
    component: Users,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          user &&
          ac &&
          ac.can(user.role).createAny('customer').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/intern/leser/:id',
    name: 'user',
    component: User,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          user &&
          ac &&
          (
            ac.can(user.role).updateAny('rental').granted ||
            (
              ac.can(user.role).updateOwn('rental').granted &&
              to.params.id === user._id
            )
          )
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/intern/bewertungen/editor/:id?',
    name: 'voteEditor',
    component: VoteEditor,
    beforeEnter: Multiguard([
      waitFor,
      async (to, from, next) => {
        if (to.params.id !== 'neu') {
          let targetVote = Store.getters['votes/get'](to.params.id)
          if (!targetVote) {
            targetVote = await Store.dispatch('votes/get', to.params.id)
          }
          if (
            user &&
            ac &&
            (
              ac.can(user.role).updateAny('vote').granted ||
              (
                ac.can(user.role).updateOwn('vote').granted &&
                targetVote.customer === user._id
              )
            )
          ) {
            next()
          } else {
            next('/')
          }
        } else {
          if (
            user &&
            ac &&
            (
              ac.can(user.role).createAny('vote').granted ||
              ac.can(user.role).createOwn('vote').granted
            )
          ) {
            next()
          }
        }
      }
    ])
  },
  {
    path: '/intern/bewertungen',
    name: 'votes',
    component: Votes,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          user &&
          ac &&
          ac.can(user.role).readOwn('vote').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/intern/nutzer/editor/:id?',
    name: 'userEditor',
    component: UserEditor,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (to.params.id !== 'neu') {
          const targetUser = Store.getters['users/get'](to.params.id)
          if (
            user &&
            ac &&
            (
              ac.can(user.role).updateAny(targetUser.role).granted ||
              (
                ac.can(user.role).updateOwn(targetUser.role).granted &&
                targetUser._id === user._id
              )
            )
          ) {
            next()
          } else {
            next('/')
          }
        } else {
          if (
            user &&
            ac &&
            ac.can(user.role).createAny('customer').granted
          ) {
            next()
          } else {
            next('/')
          }
        }
      }
    ])
  },
  {
    path: '/intern/buecher/:id',
    name: 'book',
    component: Book,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          user &&
          ac &&
          (
            ac.can(user.role).updateAny('rental').granted
          )
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/intern/buecher/editor/:id?',
    name: 'bookEditor',
    component: BookEditor,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          user &&
          ac &&
          ac.can(user.role).createAny('book').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/intern/zugang/:type/:token',
    name: 'verify',
    component: Verify,
    beforeEnter: Multiguard([
      waitFor
    ])
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  waitFor: waitFor
})

export default router
