<template>
  <div>
    <v-card
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col class="pt-0 text-right">
            <v-btn
              icon
              @click="$store.commit('SET_SHOW_LOGIN', false)"
            >
              <v-icon>
                fas fa-times
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
      </v-card-text>
    </v-card>
    <v-form v-model="loginFormValid">
      <v-card
        flat
        tile
        v-if="showLogin === 'login'"
      >
        <v-card-title>
          Anmelden
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="loginMode"
              >
                <v-radio value="loginName" label="Anmelden mit Benutzername"></v-radio>
                <v-radio value="email" label="Anmelden mit Email"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              v-if="loginMode === 'loginName'"
              class="py-0 pt-5"
              cols="12"
            >
              <v-text-field
                label="Benutzername"
                v-model="loginName"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col
              v-else
              class="py-0 pt-5"
              cols="12"
            >
              <v-text-field
                label="E-Mail"
                v-model="email"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="py-0"
              cols="12"
            >
              <v-text-field
                :append-icon="isPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @click:append="isPassword = !isPassword"
                label="Passwort"
                :type="isPassword ? 'password': ''"
                v-model="password"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="loginError && loginError !== ''">
            <v-col class="error--text">
              {{loginError}}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="px-4"
        >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!loginFormValid"
            @click="onLogin()"
            color="primary"
            block
            :loading="loading"
          >
            Anmelden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-form v-model="signupFormValid">
      <v-card
        flat
        tile
        v-if="showLogin === 'signup'"
      >
        <v-card-title>
          Jury-Mitglied werden
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Du möchtest Teil der Jury werden?
              Dann fülle einfach das nachfolgende Formular aus ...
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0 pt-5"
            >
              <v-text-field
                label="Vorname"
                v-model="firstName"
                :rules="[rules.required, rules.shortText]"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                label="Nachname"
                v-model="lastName"
                :rules="[rules.required, rules.shortText]"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                label="Benutzername"
                v-model="loginName"
                :rules="[rules.required, rules.shortText, rules.loginName]"
                :error-messages="loginNameError"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                label="E-Mail"
                v-model="email"
                :rules="[rules.required, rules.email]"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                label="Passwort"
                type="password"
                v-model="password"
                :rules="[rules.required, rules.password]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="px-4"
        >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!signupFormValid"
            @click="onSignup()"
            color="primary"
            block
            :loading="loading"
          >
            Jury-Mitglied werden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-form v-model="resetFormValid">
      <v-card
        flat
        tile
        v-if="showLogin === 'reset'"
      >
        <v-card-title>
          Passwort zurücksetzen
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Wenn du dein Passwort vergessen hast, trage hier deine hinterlegte Email-Adresse, oder falls es mehrere Zugänge mit einer Email-Adresse gibt, deinen Benutzernamen ein. An die zugehörige Email-Adresse senden wir dir einen Link zum Zurücksetzen deines Passworts.
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
                <v-radio-group
                  v-model="resetMode"
                >
                  <v-radio value="loginName" label="Passwort zurücksetzen mit Benutzername"></v-radio>
                  <v-radio value="email" label="Passwort zurücksetzen mit Email"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="resetMode === 'loginName'">
            <v-col
              cols="12"
            >
              <v-text-field
                label="Benutzername"
                v-model="loginName"
                :rules="[rules.required]"
                :error-messages="resetError"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="resetMode === 'email'">
            <v-col
              cols="12"
            >
              <v-text-field
                label="Email"
                v-model="email"
                :rules="[rules.required, rules.email]"
                :error-messages="resetError"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="pa-4"
        >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!resetFormValid"
            @click="onReset()"
            color="primary"
            block
            :loading="loading"
          >
            Passwort zurücksetzen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-card
      flat
      tile
      v-if="showLogin === 'thanks'"
    >
      <v-card-title>
        Vielen Dank
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            Du hast einen Bestätigungslink per Email erhalten. Bitte überprüfe dein Email-Postfach!
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      flat
      tile
      v-if="showLogin === 'resetThanks'"
    >
      <v-card-title>
        Vielen Dank
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            Du hast eine Email zum Zurücksetzen deines Passworts erhalten.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              @click="setShowLogin('login')"
              color="primary"
              block
            >
              Zurück zur Anmeldung
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      flat
      tile
    >
      <v-card-text>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col class="text-center">
            <v-btn
              v-if="showLogin === 'login' || showLogin === 'signup'"
              x-small
              text
              color="secondary"
              @click="setShowLogin('reset')"
            >
              Passwort vergessen
            </v-btn>
            <v-btn
              v-if="showLogin === 'signup' || showLogin === 'thanks' || showLogin === 'reset'"
              x-small
              text
              color="secondary"
              @click="setShowLogin('login')"
            >
              Anmelden
            </v-btn>
            <v-btn
              v-if="showLogin === 'login' || showLogin === 'reset'"
              x-small
              text
              color="secondary"
              @click="setShowLogin('signup')"
            >
              Jury-Mitglied werden
            </v-btn>
          </v-col>
      </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'Login',
  data: () => ({
    isPassword: true,
    loginMode: 'email',
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    loginName: null,
    loginNameError: [],
    loginError: '',
    loginFormValid: false,
    signupFormValid: false,
    resetFormValid: false,
    showResetPassword: false,
    resetError: '',
    resetMode: 'email',
    loading: false
  }),
  computed: {
    ...mapGetters([
      'rules',
      'showLogin'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'authenticate',
      'user'
    ]),
    ...mapActions('authManagement', {
      'createAuth': 'create'
    }),
    ...mapActions('users', {
      'createUser': 'create'
    }),
    ...mapMutations({
      'setShowLogin': 'SET_SHOW_LOGIN'
    }),
    async onLogin () {
      this.loading = true
      try {
        if (this.loginMode === 'loginName') {
          await this.authenticate({ strategy: 'localName', loginName: this.loginName, password: this.password })
        } else {
          await this.authenticate({ strategy: 'local', email: this.email, password: this.password })
        }
        this.$store.commit('SET_INIT', true)
        this.$router.options.waitFor()
        this.loading = false
        this.$store.commit('SET_SHOW_LOGIN', false)
      } catch (error) {
        if (error.code === 401) {
          this.loginError = 'Passwort oder Email-Adresse ungültig.'
          this.showResetPassword = true
        } else if (error.code === 409) {
          this.loginError = error.message
        } else {
          this.loginError = error.message
        }
        this.loading = false
      }
    },
    onSignup () {
      this.loading = true
      const map = {
        firstName: this.firstName,
        lastName: this.lastName,
        loginName: this.loginName,
        email: this.email,
        password: this.password
      }
      this.createUser([ map, {} ])
        .then((result) => {
          this.loading = false
          this.setShowLogin('thanks')
        })
        .catch((error) => {
          if (error.errors && error.errors.loginName) {
            this.loginNameError = ['Es existiert bereits ein Zugang für diesen Benutzernamen.']
          } else {
            this.loginNameError = error.message
          }
          this.loading = false
        })
    },
    onReset () {
      this.loading = true
      let value
      if (this.resetMode === 'email') {
        value = { 'email': this.email }
      } else if (this.resetMode === 'loginName') {
        value = { 'loginName': this.loginName }
      }
      this.createAuth({ action: 'sendResetPwd', value })
        .then(() => {
          this.setShowLogin('resetThanks')
          this.loading = false
        })
        .catch(error => {
          if (error.message === 'More than 1 user selected.') {
            this.resetError = 'Für diese Email-Adresse existieren mehrere Konten, bitte setze das gewünschte Konto über die Angabe des Benutzernamens zurück.'
          } else if (error.className === 'bad-request') {
            this.resetError = 'Es existiert kein Zugang mit dieser Email-Adresse.'
          } else {
            this.resetError = error
          }
          this.loading = false
        })
    }
  },
  watch: {
    loginName () {
      this.loginNameError = []
      this.resetError = ''
    },
    email () {
      this.resetError = ''
    }
  }
}
</script>
