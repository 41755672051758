<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            EINSTELLUNGEN
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form
              v-model="formValid"
            >
              <v-row>
                <v-col>
                  <v-menu
                    v-model="showVotingEndPickerMenu"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="computedEndDate"
                        label="Bewertungs-Ende"
                        readonly
                        v-on="on"
                        @click:clear="votingEnd = null"
                        color="primary"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="votingEnd"
                      @change="showVotingEndPickerMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    v-model="showRentalEndPickerMenu"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="computedRentalEndDate"
                        label="Verleih-Ende"
                        readonly
                        v-on="on"
                        @click:clear="rentalEnd = null"
                        color="primary"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="rentalEnd"
                      @change="showRentalEndPickerMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Ausleihedauer in Tagen"
                    v-model="defaultRentalDays"
                    color="primary"
                    type="number"
                    :rules="[rules.numeric, rules.required]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Maximale Anzahl Verlängerungen"
                    v-model="maxExtends"
                    color="primary"
                    type="number"
                    :rules="[rules.numeric, rules.required]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="body-1 font-weight-bold pb-0">
                  Mitmachen
                </v-col>
                <v-col class="pt-3 mb-1 py-6">
                  <tiptap-vuetify
                    v-model="participate"
                    :card-props="{ tile: true, flat: true, outlined: true }"
                    :extensions="extensions"
                  >
                  </tiptap-vuetify>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="body-1 font-weight-bold pb-0">
                  Unke
                </v-col>
                <v-col class="mb-1 py-6">
                  <tiptap-vuetify
                    v-model="unke"
                    :card-props="{ tile: true, flat: true, outlined: true }"
                    :extensions="extensions"
                  >
                  </tiptap-vuetify>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="body-1 font-weight-bold pb-0">
                  Jury
                </v-col>
                <v-col class="mb-1 py-6">
                  <tiptap-vuetify
                    v-model="jury"
                    :card-props="{ tile: true, flat: true, outlined: true }"
                    :extensions="extensions"
                  >
                  </tiptap-vuetify>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="body-1 font-weight-bold pb-0">
                  Team
                </v-col>
                <v-col class="mb-6 py-6">
                  <tiptap-vuetify
                    v-model="team"
                    :card-props="{ tile: true, flat: true, outlined: true }"
                    :extensions="extensions"
                  >
                  </tiptap-vuetify>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4 py-6">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="
                !formValid
              "
              @click="saveSettings()"
              color="success"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TiptapVuetify, Bold, Italic, Strike, Underline, BulletList, OrderedList, ListItem } from 'tiptap-vuetify'

export default {
  name: 'userEditor',
  components: {
    TiptapVuetify
  },
  data: function () {
    return {
      formValid: false,
      votingEnd: null,
      defaultRentalDays: null,
      maxExtends: null,
      showVotingEndPickerMenu: false,
      rentalEnd: null,
      showRentalEndPickerMenu: false,
      extensions: [
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList
      ],
      participate: undefined,
      unke: undefined,
      jury: undefined,
      team: undefined
    }
  },
  mounted () {
    this.adapt()
  },
  computed: {
    ...mapGetters([
      'ac',
      'rules'
    ]),
    ...mapGetters('auth', {
      'user': 'user'
    }),
    ...mapGetters('settings', {
      'settings': 'list'
    }),
    computedEndDate () {
      return this.votingEnd ? this.$moment(this.votingEnd).format('dddd, DD.MM.YYYY') : ''
    },
    computedRentalEndDate () {
      return this.rentalEnd ? this.$moment(this.rentalEnd).format('dddd, DD.MM.YYYY') : ''
    }
  },
  watch: {
    settings () {
      this.adapt()
    }
  },
  methods: {
    ...mapActions('settings', {
      'patchSettings': 'patch'
    }),
    adapt () {
      if (this.settings) {
        console.log(this.settings[0].pages?.find(p => p.type === 'participate'))
        this.votingEnd = this.$moment(this.settings[0].votingEnd).format('YYYY-MM-DD')
        this.defaultRentalDays = this.settings[0].defaultRentalDays
        this.maxExtends = this.settings[0].maxExtends
        this.rentalEnd = this.$moment(this.settings[0].rentalEnd).format('YYYY-MM-DD')
        this.participate = this.settings[0].pages?.find(p => p.type === 'participate')?.text
        this.unke = this.settings[0].pages?.find(p => p.type === 'unke')?.text
        this.jury = this.settings[0].pages?.find(p => p.type === 'jury')?.text
        this.team = this.settings[0].pages?.find(p => p.type === 'team')?.text
      }
    },
    async saveSettings () {
      const map = {
        votingEnd: this.votingEnd,
        defaultRentalDays: this.defaultRentalDays,
        maxExtends: this.maxExtends,
        rentalEnd: this.rentalEnd,
        pages: [
          {
            type: 'participate',
            text: this.participate
          },
          {
            type: 'unke',
            text: this.unke
          },
          {
            type: 'jury',
            text: this.jury
          },
          {
            type: 'team',
            text: this.team
          }
        ]
      }
      await this.patchSettings([this.settings[0]._id, map, {}])
    }
  }
}
</script>
