<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            DIE JURY
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pt-5 px-5">
          <v-card-text>
            <div v-html="settings[0]?.pages?.find(p => p.type === 'jury')?.text"></div>
             <v-row>
              <v-col>
                <v-btn small color="success" @click="setShowLogin('signup')">Du kannst dich direkt hier auf der Seite als Jury-Mitglied registrieren</v-btn>.
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'about',
  components: {
  },
  computed: {
    ...mapGetters('users', {
      'users': 'list'
    }),
    ...mapGetters('settings', {
      'settings': 'list'
    })
  },
  methods: {
    ...mapMutations({
      'setShowLogin': 'SET_SHOW_LOGIN'
    })
  }
}
</script>
