<template>
  <div>
    <v-row>
        <v-col>
          <v-row>
            <v-col
              align-self="center"
              class="display-3"
            >
              BÜCHER|NNEN EDITOR
            </v-col>
          </v-row>
        </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form
              v-model="formValid"
            >
              <v-row>
                <v-col>
                  <v-text-field
                    label="Titel*"
                    v-model="baseTitle"
                    :rules="[rules.required]"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny('book').attributes.includes('*') ||
                        ac.can(user.role).updateAny('book').attributes.includes('baseTitle')
                      )
                    "
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Exemplar-Bezeichnung"
                    v-model="copyTitle"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny('book').attributes.includes('*') ||
                        ac.can(user.role).updateAny('book').attributes.includes('copyTitle')
                      )
                    "
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Autor*"
                    v-model="author"
                    :rules="[rules.required]"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny('book').attributes.includes('*') ||
                        ac.can(user.role).updateAny('book').attributes.includes('author')
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="ISBN"
                    v-model="isbn"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny('book').attributes.includes('*') ||
                        ac.can(user.role).updateAny('book').attributes.includes('isbn')
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    label="Beschreibung*"
                    v-model="description"
                    :rules="[rules.required]"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny('book').attributes.includes('*') ||
                        ac.can(user.role).updateAny('book').attributes.includes('description')
                      )
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="audience"
                    :items="['Alle', '10-12', '13+', 'Goldene Unke', 'Unke Ü18']"
                    label="Altergruppe*"
                    :rules="[rules.required]"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny('book').attributes.includes('*') ||
                        ac.can(user.role).updateAny('book').attributes.includes('audience')
                      )
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="body-1">
                  Coverbild (max. 5MB)*<br>
                  <span v-if="(this.pics.length + this.picsToUpload.length) === 0" class="body-2 error--text">{{ uploadError ? uploadError : 'Pflichteingabe.' }}.</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-for="(pic, i) in pics"
                  :key="i"
                  cols="12"
                  sm="4"
                  md="3"
                >
                  <v-img
                    :aspect-ratio="3/4"
                    :src="computedServerUrl + '/pics/books/' + pic"
                  >
                    <v-container class="fill-height">
                      <v-row class="ma-0">
                        <v-col class="text-center">
                          <v-btn
                            fab
                            color="primary"
                            depressed
                            @click="removePic(i)"
                          >
                            <v-icon small>fas fa-trash</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-img>
                </v-col>
                <!-- Tmp images -->
                <v-col
                  v-for="picToUpload in picsToUpload"
                  :key="picToUpload"
                  cols="12"
                  sm="4"
                  md="3"
                >
                  <v-img
                    :aspect-ratio="3/4"
                    :src="picToUpload"
                  >
                    <v-container class="fill-height">
                      <v-row class="ma-0">
                        <v-col class="text-center">
                          <v-hover v-slot:default="{ hover }">
                            <v-btn
                              fab
                              :color="hover ? 'primary' : 'info'"
                              depressed
                              @click="removePicToUpload(picsToUpload)"
                            >
                              <v-icon small>fas fa-trash</v-icon>
                            </v-btn>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-img>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  v-if="pics.length + picsToUpload.length === 0"
                >
                  <label for="bookImagesFileInput">
                    <v-img
                      class="text-center fill-height clickable"
                      :aspect-ratio="3/4"
                    >
                      <v-container class="add-more" fill-height>
                        <v-row class="ma-0">
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-col>
                              <input
                                v-on:change="onBookImageFileChange"
                                style="height: 0px; width: 0px; opcity: 0;"
                                type="file"
                                id="bookImagesFileInput"
                                name="image"
                                accept="image/png, image/jpeg"
                              />
                              <v-icon
                                :color="hover ? 'primary' : 'info'"
                              >
                                fa fa-image
                              </v-icon>
                              <div :class="hover ? 'primary--text' : 'info--text'">+   Cover hinzufügen</div>
                            </v-col>
                          </v-hover>
                        </v-row>
                      </v-container>
                    </v-img>
                  </label>
                </v-col>
              </v-row>
              <v-row v-if="$route.params.id === 'neu'">
                <v-col>
                  <v-text-field
                    type="number"
                    v-model="copies"
                    label="Anzahl der Exemplare"
                    :rules="[(value) => value > 0 || 'Mindestens ein Exemplar.']"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4 py-6">
            <template v-if="$route.params.id !== 'neu'">
              <v-btn
                v-if="computedBook.isActive"
                @click="archiveBook()"
                color="warning"
              >
                Archivieren
              </v-btn>
              <v-btn
                v-else
                @click="activateBook()"
                color="primary"
              >
                Aktivieren
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!formValid || (this.pics.length + this.picsToUpload.length) === 0 || loading || uploadError"
              @click="saveBook()"
              color="success"
              :loading="loading"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import feathersClient from '@/feathers-client'
import { mapGetters, mapActions } from 'vuex'
import Jimp from 'jimp'

export default {
  name: 'bookEditor',
  data: function () {
    return {
      uploadService: undefined,
      formValid: false,
      audience: 'Alle',
      baseTitle: null,
      copyTitle: null,
      isbn: null,
      author: null,
      description: null,
      pics: [],
      picsToUpload: [],
      copies: 1,
      loading: false,
      uploadError: null
    }
  },
  mounted: function () {
    this.adapt()
    this.uploadService = feathersClient.service('uploads')
  },
  computed: {
    ...mapGetters([
      'ac',
      'rules'
    ]),
    ...mapGetters('auth', {
      'user': 'user'
    }),
    ...mapGetters('books', {
      'getBook': 'get'
    }),
    computedBook () {
      if (this.$route.params.id !== 'neu') {
        return this.getBook(this.$route.params.id)
      } else {
        return undefined
      }
    },
    computedServerUrl () {
      return process.env.VUE_APP_SERVER_URL
    }
  },
  watch: {
    computedBook () {
      this.adapt()
    },
    picsToUpload () {
      if (this.picsToUpload) {
        for (const pic of this.picsToUpload) {
          if (((pic.length * (3 / 4)) / 1000000) > 5) {
            this.picsToUpload = []
            this.uploadError = 'Maximale Größe von 5 MB überschritten.'
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('books', {
      'createBook': 'create',
      'patchBook': 'patch'
    }),
    adapt () {
      if (this.computedBook) {
        this.baseTitle = this.computedBook.baseTitle
        this.copyTitle = this.computedBook.copyTitle
        this.author = this.computedBook.author
        this.isbn = this.computedBook.isbn
        this.description = this.computedBook.description
        this.audience = this.computedBook.audience
        this.pics = JSON.parse(JSON.stringify(this.computedBook.pics))
      }
    },
    onBookImageFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      this.updateBookImageName(files[0])
    },
    updateBookImageName (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.picsToUpload.push(e.target.result)
      }
      reader.readAsDataURL(file)
    },
    removePic (i) {
      this.uploadError = null
      this.pics.splice(i, 1)
    },
    removePicToUpload (picToUpload) {
      this.uploadError = null
      this.picsToUpload.splice(this.picsToUpload.indexOf(picToUpload), 1)
    },
    async saveBook () {
      this.loading = true
      // Remove existing pics
      if (this.computedBook) {
        for (const existingPic of this.computedBook.pics) {
          if (!this.pics.includes(existingPic)) {
            await this.uploadService.remove({ fileName: existingPic, path: '/books/' })
          }
        }
      }
      // Upload new Pics
      let picPaths = []
      for (const picToUpload of this.picsToUpload) {
        const uri = await this.processImage(picToUpload)
        const picName = await this.uploadService.create({ uri: uri, path: '/books/' })
        picPaths.push(picName)
      }
      // Create new pic path array
      if (this.computedBook) {
        picPaths = picPaths.concat(this.pics)
      }
      const map = {
        baseTitle: this.baseTitle,
        author: this.author,
        isbn: this.isbn,
        description: this.description,
        audience: this.audience,
        pics: picPaths
      }
      if (this.copyTitle) {
        map.copyTitle = this.copyTitle
      }
      // Send to server
      if (this.$route.params.id === 'neu') {
        for (let i = 1; i <= parseInt(this.copies); i++) {
          if (this.copies > 1) {
            map.copyTitle = '(' + i + ')'
          }
          await this.createBook(map, { query: { $populate: ['rental', 'votes'] } })
        }
      } else {
        await this.patchBook([this.computedBook._id, map, { query: { $populate: ['rental', 'votes'] } }])
      }
      this.$router.push({ path: '/buecher', query: this.$router.options.tmpQuery })
    },
    async archiveBook () {
      await this.patchBook([this.computedBook._id, { isActive: false }, { query: { $populate: ['rental', 'votes'] } }])
      this.$router.push({ path: '/buecher', query: this.$router.options.tmpQuery })
    },
    async activateBook () {
      await this.patchBook([this.computedBook._id, { isActive: true }, { query: { $populate: ['rental', 'votes'] } }])
      this.$router.push({ path: '/bucher', query: this.$router.options.tmpQuery })
    },
    async processImage (file) {
      const size = (file.length * (3 / 4)) / 1000000
      let quality
      const calcedQuality = 80 - (size * 4)
      if (calcedQuality < 50) {
        quality = 50
      } else if (calcedQuality > 100) {
        quality = 100
      } else {
        quality = calcedQuality
      }
      const image = await Jimp.read(Buffer.from(file.split(',')[1], 'base64'))
      if (image.bitmap.width > 720) {
        image.resize(720, Jimp.AUTO)
      }
      if (image.bitmap.height > 720) {
        image.resize(Jimp.AUTO, 720)
      }
      image.quality(quality)
      const finalUri = await image.getBase64Async(image.getMIME())
      return finalUri
    }
  }
}
</script>

<style scoped>
  .add-more {
    border: 2px dashed #b3c1cc !important;
  }
  .add-more:hover {
    border: 2px dashed #79a2c8 !important;
  }
</style>
