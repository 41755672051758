<template>
  <v-app v-show="show">
    <v-navigation-drawer
      app
      :value="showNav"
      :mobile-break-point=0
      temporary
    >
      <v-list>
        <v-list-item
          color="success"
          :input-value="true"
        >
          <v-list-item-content
            class="title"
          >
              Hauptmenü
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              color="success"
              class="mt-1"
              @click="setShowNav(false)"
            >
                <v-icon>
                  fas fa-times
                </v-icon>
              </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          @click="$router.push('/buecher')"
        >
          <v-list-item-content>
            Bücher
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="goToSection('unke')"
        >
          <v-list-item-content>
            Ulmer Unke
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="winners.length > 0"
          @click="$router.push('/siegertitel/alle')"
        >
          <v-list-item-content>
            Siegertitel
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="goToSection('jury')"
        >
          <v-list-item-content>
            Jury
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="goToSection('team')"
        >
          <v-list-item-content>
            Team
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="$router.push('/info/datenschutz')"
        >
          <v-list-item-content>
            Datenschutz
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="$router.push('/info/impressum')"
        >
          <v-list-item-content>
            Impressum
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template
        v-if="isAuthenticated"
      >
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-if="
              (computedAc && ac.can(user.role).createAny('customer').granted) ||
              (computedAc && ac.can(user.role).createAny('customer').granted) ||
              (computedAc && ac.can(user.role).createAny('book').granted) ||
              (computedAc && ac.can(user.role).updateAny('vote').granted) ||
              (computedAc && ac.can(user.role).updateAny('winner').granted) ||
              (computedAc && ac.can(user.role).updateAny('setting').granted)
            "
            color="success"
            :input-value="true"
          >
            <v-list-item-content
              class="title"
            >
                Intern
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="$router.push('/intern/nutzer')"
            v-if="computedAc && ac.can(user.role).createAny('customer').granted"
          >
            <v-list-item-content>
              Nutzer|nnen verwalten
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="$router.push('/buecher')"
            v-if="computedAc && ac.can(user.role).createAny('book').granted"
          >
            <v-list-item-content>
              Bücher|nnen verwalten
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="computedAc && ac.can(user.role).updateAny('vote').granted"
            @click="$router.push('/intern/bewertungen')"
          >
            <v-list-item-content>
              Bewertungen verwalten
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="computedAc && ac.can(user.role).updateAny('winner').granted"
            @click="$router.push('/intern/siegertitel')"
          >
            <v-list-item-content>
              Siegertitel verwalten
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="computedAc && ac.can(user.role).updateAny('setting').granted"
            @click="$router.push('/intern/einstellungen')"
          >
            <v-list-item-content>
              Einstellungen
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-right
      flat
      dark
      color="primary"
    >
      <v-btn
        icon
        class="mr-3"
        @click="$store.commit('SET_SHOW_NAV', !showNav)"
      >
        <v-icon>
          {{showNav ? 'fas fa-times' : 'fas fa-bars'}}
        </v-icon>
      </v-btn>
      <img
        height="44px"
        src="/img/unke-logo.png"
        @click="$router.push('/')"
        class="clickable"
      >
      <v-toolbar-title
        @click="$router.push('/')"
        class="ml-3 font-weight-bold clickable"
      >
        DIE ULMER UNKE
      </v-toolbar-title>
      <v-toolbar-title v-if="$vuetify.breakpoint.width > 650" class="ml-2 font-weight-thin">Kinder- und Jugendbuchpreis</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        offset-y
        v-if="isAuthenticated"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            dark
            icon
            v-on="on"
          >
            <v-icon>fas fa-user-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="$router.push('/intern/nutzer/editor/' + user._id)"
          >
            <v-list-item-title>Mein Profil</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="user && user.customerId && user.customerId !== ''"
            @click="$router.push('/intern/leser/' + user._id)"
          >
            <v-list-item-title>Meine Ausleihen</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="user && user.customerId && user.customerId !== ''"
            @click="$router.push('/intern/bewertungen')"
          >
            <v-list-item-title>Meine Bewertungen</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="initLogout()"
          >
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-content class="my-5 mb-12 pb-12">
      <v-container>
        <router-view/>
      </v-container>
    </v-content>
    <v-dialog
      tile
      persistent
      max-width="500"
      overlay-opacity=".7"
      v-model="computedShowLogin"
      overlay-color="primary"
    >
      <Login/>
    </v-dialog>
      <v-footer
        app
        color="primary"
        dark
      >
        <v-row
          no-gutters
        >
          <v-col align-self="center" cols="12" md="6" :class="$vuetify.breakpoint.mdAndUp ? '' : 'text-center'">
            <img class="mt-1" height="30px" src="/img/sjr-logo.svg"/>
          </v-col>
          <v-col align-self="center" :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-center'">
          <v-spacer></v-spacer>
          <v-btn
            text
            small
            @click="$router.push('/info/datenschutz')"
          >
            DATENSCHUTZ
          </v-btn>
          <v-btn
            text
            small
            @click="$router.push('/info/impressum')"
          >
            IMPRESSUM
          </v-btn>
          <template v-if="isAuthenticated">
            <v-btn
              small
              text
              @click="initLogout()"
            >
              ABMELDEN
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              @click="setShowLogin('login')"
              small
              text
            >
              ANMELDEN
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Login from './components/Login'

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'App',
  components: {
    Login
  },
  mounted () {
    this.changeLogin()
    window.addEventListener('storage', this.onStorageUpdate)
  },
  computed: {
    ...mapGetters([
      'showNav',
      'showLogin',
      'show',
      'ac'
    ]),
    ...mapGetters('auth', {
      'user': 'user',
      'isAuthenticated': 'isAuthenticated'
    }),
    ...mapGetters('winners', {
      'winners': 'list'
    }),
    computedShowLogin () {
      return this.showLogin && this.showLogin !== ''
    },
    computedAc () {
      return (this.isAuthenticated && this.ac) || false
    }
  },
  methods: {
    onStorageUpdate (event) {
      if (event.key === 'feathers-jwt' && event.newValue === null) {
        this.initLogout()
      }
    },
    ...mapActions('auth', [
      'logout'
    ]),
    ...mapMutations({
      'setShowLogin': 'SET_SHOW_LOGIN',
      'setSection': 'SET_SECTION',
      'setShowNav': 'SET_SHOW_NAV'
    }),
    initLogout () {
      this.logout()
        .then(() => {
          history.pushState({}, null, '/')
          location.reload()
        })
    },
    changeLogin () {
      if (this.$route.params.showLogin === 'login') {
        this.setShowLogin('login')
      } else if (this.$route.params.showLogin === 'registrieren') {
        this.setShowLogin('signup')
      } else if (this.$route.params.showLogin === 'zuruecksetzen') {
        this.setShowLogin('reset')
      } else if (this.$route.params.showLogin === 'unke') {
        this.goToSection('unke')
      } else if (this.$route.params.showLogin === 'team') {
        this.goToSection('team')
      } else if (this.$route.params.showLogin === 'jury') {
        this.goToSection('jury')
      } else if (this.$route.params.showLogin === 'impressum') {
        return this.$router.push('/info/impressum')
      } else if (this.$route.params.showLogin === 'datenschutz') {
        return this.$router.push('/info/datenschutz')
      }
      this.$route.params.showLogin = false
      window.history.pushState({}, null, '/')
    },
    goToSection (id) {
      this.$router.push('/')
      window.history.pushState({}, null, '/' + id)
      setTimeout(() => {
        this.setSection(id)
      }, 300)
    }
  }
}
</script>

<style>
  .clickable {
    cursor: pointer;
  }
  .v-data-table.multiline td {
      vertical-align: top;
      padding-top: 16px;
      padding-bottom: 16px
  }

  p, ul, ol {
    width: 100% !important;
  }

  li > p {
    margin-bottom: 6px !important;
  }

  ul, ol {
    margin-bottom: 18px !important;
  }
</style>
