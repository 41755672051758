<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
              align-self="center"
              class="display-3"
            >
              DIE ULMER UNKE
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pt-5 px-5">
          <v-card-text>
            <div v-html="settings[0]?.pages?.find(p => p.type === 'unke')?.text"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'about',
  components: {
  },
  computed: {
    ...mapGetters('users', {
      'users': 'list'
    }),
    ...mapGetters('settings', {
      'settings': 'list'
    })
  }
}
</script>
